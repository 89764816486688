<template>

    <div class="bg" :class="getBackgroundClass()">
    </div>

    <div class="container" v-if="loaded">
        <div class="logocont">
            <div class="logo" :class="getLogoClass()">
            </div>
        </div>
        <div class="boardcont">

            <div id="1" class="tabcontent">
                <div class="board">
                    <div class="entrycont" v-for="(column, index) in mvpChunks[selectedPage]" :key="index">
                        <div class="playercont">
                            <div class="player" v-for="(member, index) in column" :key="index" :class="{'notcompliant' : !isCompliant(member.compliance_pct) }">
                                <p class="teamlogo" :class="member.logo"></p>
                                <p class="rank">{{ member.rank +1 }}.</p>
                                <p class="name">{{ member.first_name }} {{ member.last_name }}</p>
                                <p class="score">{{ Math.round(member.total) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>



<script setup>

import { onMounted, computed, ref } from 'vue'
import { useBattleData } from '../composables/battledata.js'
import { useBattleLoader } from '../composables/battleloader.js'
import { useBattleTools } from '../composables/battletools.js';
// import { useRouter } from 'vue-router'
// import silkrs from '@/assets/elite2024/silkrs.png';

let reload_timer = undefined
let slide_timer = undefined
// let switch_timer = undefined
// let daily = false
// const selectedDateItem = ref(0)
const selectedPage = ref(0)
const selectedMVP = ref(0)
const daily = ref(false)
daily.value = new URL(window.location.href).pathname.startsWith('/daily')


const { mvps } = useBattleData({ daily: daily.value, filter_date: false, sort_excludes_additional_points: true, cherries: false })
const { initBattle, loaded, loadProductionData } = useBattleLoader()
// const { getShortDayName, isCompliant} = useBattleTools()
const { isCompliant } = useBattleTools()

// const getButtonTeamsText = (page, teams_length) => {
//     const start = 1 + (page * pageSize)
//     const end = (start + teams_length) - 1
//     return `${start} - ${end}`
// }

selectedMVP.value = 0

// const getButtonTxtNw = () => {
// return [
//         'Energy',
//         'Charity',
//         'Media',
//         'Lottery',
//         'All'
//     ]
// }

const getLogoClass = () => {
    if (selectedMVP.value === 0) {
        return 'logoEnergy'
    } else if (selectedMVP.value === 1) {
        return 'logoCharity'
    } else if (selectedMVP.value === 2) {
        return 'logoMedia'
    } else if (selectedMVP.value === 3) {
        return 'logoLottery'
    } else {
        return 'logoAll'
    }
}

const getBackgroundClass = () => {
    if (selectedMVP.value === 0) {
        return 'bgyellow'
    } else if (selectedMVP.value === 1) {
        return 'bgred'
    } else if (selectedMVP.value === 2) {
        return 'bgblue'
    } else if (selectedMVP.value === 3) {
        return 'bgyellow'
    } else {
        return 'bgyellow'
    }
}

const mvpChunks = computed(() => {

    if (selectedMVP.value === 0) {
        return mvps.value['energy']
    }
    if (selectedMVP.value === 1) {
        return mvps.value['charity']
    }
    if (selectedMVP.value === 2) {
        return mvps.value['media']
    }
    if (selectedMVP.value === 3) {
        return mvps.value['lottery']
    }
    return mvps.value['all']

})

onMounted(async () => {
    await initBattle().then(() => {
        setReloadTimer()
        setSlideTimer()
    })
    // setCurrentDate()
    loaded.value = true
})

function setReloadTimer() {
    if (reload_timer) {
        clearInterval(reload_timer)
        reload_timer = undefined
    }
    reload_timer = setInterval(() => loadProductionData(), 120000)
}

function setSlideTimer() {
    if (slide_timer) {
        clearInterval(slide_timer)
        slide_timer = undefined
    }
    slide_timer = setInterval(() => slideMVP(), 10000)
}


function slideMVP() {
    // const mvpCount = 5
    const currentMVP = selectedMVP.value
    const pageCount = mvpChunks.value.length

    if (selectedPage.value < pageCount - 1) {
        selectedPage.value += 1
    } else {
        selectedMVP.value = currentMVP < 4 ? currentMVP + 1 : 0
        selectedPage.value = 0
    }

    console.log ('currentMVP', pageCount, currentMVP, selectedPage.value)

}
// function selectDate(dateIndex) {
//     selectedDateItem.value = dateIndex
//     selectedPage.value = 0
//     // setSlideTimer()
// }

// function selectPage(pageIndex) {
//     selectedPage.value = pageIndex
//     setSlideTimer()
// }


</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');


.container {
    /* height: 100%; */
    width: 100%;

    font-family: "Teko", sans-serif;
    box-sizing: border-box;
    margin-top: 60px;
}

.board {
    margin-left: 250px;
    display: flex;
    flex-wrap: wrap;
}

.boardcont {
    width:100%;
    transform: skew(-16.3deg);
}

.logocont {

    justify-content: flex-start;
    display: flex;
}

.playercont {
    margin-top: -10px;
}

.logo {
    text-align: center;
    width: 300px;
    height: 200px;
    margin-left: 350px;

    background-repeat: no-repeat;
    background-attachment: scroll;
    /* background-position: 0% 0%; */
    background-size: contain;
    justify-content: center;
    display: flex;
}


.logoAll {
    background-image: url("../assets/big2024/BIGBattleMVP2.png");
}

.logoEnergy {
    background-image: url("../assets/big2024/LOGOEnergy.png");
}

.logoCharity {
    background-image: url("../assets/big2024/LOGOCharity.png");
}

.logoMedia{
    background-image: url("../assets/big2024/LOGOMedia.png");
}

.logoLottery{
    background-image: url("../assets/big2024/LOGOLottery.png");
}

.coldcallers {
    background-image: url("../assets/big2024/TEAMColdcallers.png");
}

.teamlogo {
    background-repeat: no-repeat;
    /* background-attachment: scroll; */
    background-size: cover;
    height: 47px;
    width: 47px;
    /* margin-right: 5px; */
}


/*
motivation
*/
.motivation {
    background-image: url("../assets/big2024/TEAMMotivation.png");
}
/*
klikkers
*/
.klikkers {
    background-image: url("../assets/big2024/TEAMDeklikkers.png");
}

/*
ballie
*/
.ballie {
    background-image: url("../assets/big2024/TEAMBallie.png");
}
/*
noname
*/
.noname {
    background-image: url("../assets/big2024/TEAMNoname.png");
}
/*
herres
*/
.herres {
    background-image: url("../assets/big2024/TEAMHerres.png");
}
/*
coldcallers
*/
.coldcallers {
    background-image: url("../assets/big2024/TEAMColdcallers.png");
}
/*
toosie
*/
.toosie {
    background-image: url("../assets/big2024/TEAMToosie.png");
}
/*
dealmakers
*/
.dealmakers {
    background-image: url("../assets/big2024/dealmakers.png");
}
/*
beukers
*/
.beukers {
    background-image: url("../assets/big2024/TEAMBeuker.png");
}
/*
wolves
*/
.wolves {
    background-image: url("../assets/big2024/cherries/Group 198.png");
}
/*
frozen
*/
.frozen{
    background-image: url("../assets/big2024/cherries/Group 201.png");
}
/*
reindeers
*/
.reindeers{
    background-image: url("../assets/big2024/cherries/Group 202.png");
}
/*
angels
*/
.angels{
    background-image: url("../assets/big2024/cherries/Group 200.png");
}
/*
sneeuwvlokjes
*/
.sneeuwvlokjes{
    background-image: url("../assets/big2024/cherries/Group 199.png");
}
/*
pioniers
*/
.pioniers {
    background-image: url("../assets/big2024/TEAMPioniers.png");
}
/*
strijders
*/
.strijders {
    background-image: url("../assets/big2024/Strijders.png");
}
/*
santas
*/
.santas {
    background-image: url("../assets/big2024/cherries/Group 204.png");
}


.entrycont {
    width: 380px;
    padding: 20px 30px 30px 30px;
    background-color: #ffffff;
    border-radius: 9px;
    margin-bottom: 30px;
    margin-right: 30px;
    min-height: 600px;
}

.buttoncont {
    display: flex;
}

.notcompliant {
    opacity: 0.5;
}

.player {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
    line-height: 55px;
    height: 47px;
}

.name {
    width: 70%;
}

.compli {
    text-align: center;
    width: 10%;
}

.topcont {
    display: flex;
    flex-direction: column;
    height: 91px;
    width: 100%;
    flex-wrap: wrap;
    /* justify-content: flex-start; */
}

.team-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-basis: 100%;
    width: 50%;
    max-width: 50%;
    min-width: 45%;

}

.team-rank {
    font-size: 140px;
    font-weight: 600;
    line-height: 112px;
    height: 91px;
    flex-basis: 60px;
}

.team-name {
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    font-size: 38px;
    line-height: 29px;
}



.rank {
    margin-right: 5px;
}

/*.name {

} */

.score {

    text-align: right;
}

.team-score {
    border-radius: 2.39px;
    font-weight: 700;
    font-size: 29px;
    line-height: 24px;
    color: #FAF121;
    padding: 6px 10px 2px 10px;
    background-color: #000000;
    width: fit-content;
}

.team-logo {

    display: flex;
}

.team-logo img{
    width: 91px;
    margin-left: auto;

}

.align-center {
    align-self: center;
}

.just-center {
    justify-self: center;
}

.rb {
    --bg-size: 400%;
    --color-one: #dbe8c0;
    --color-two: #a0d038;
    background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
    color: transparent;
    background-clip: text;
}

.rbbg {
    --bg-size: 400%;
    --color-one: #dbe8c0;
    --color-two: #a0d038;
    background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
}


.rb {
    animation: move-bg 8s linear infinite;
}

@keyframes move-bg {
    to {
        background-position: var(--bg-size) 0;
    }
}



.rbbg {
    animation: move-bg 8s linear infinite;
}

@keyframes move-bg {
    to {
        background-position: var(--bg-size) 0;
    }
}

.linkwrap {
    display: flex;
    /* margin-right: 3%;
    margin-left: 4%; */
}

.tablinks.pages {
    padding: 8px 18px;
    width: unset;
    font-size: 30px;
    line-height: 1.5;
}

.crowns {
    height: 24px;
    margin-top: 5px;
}

.crown {
    margin-left: 5px;
}

.tablinks.dates.week {
    padding: 15px 15px;
    width: unset;
    font-size: 25px;
    background-color: #ffffff;
    color: #091938;
    line-height: 0;
}

.tablinks.dates.daily {
    padding: 10px 0px;
    font-size: 25px;
    width: 52px;
}

.tablinks.dates.week::before {
    display: inline-block;
    content:url('../assets/elite2024/eye.png'); /* with class ModalCarrot ??*/
    margin-right: 5px;
}

.tablinks {
    background-color: #F6B400;
    color: black;
    /* margin: 0px 10px 0 10px; */
    border-radius: 9px;
    font-size: 25px;
    text-decoration: none;
    outline: none;
    border: none;
    font-weight: bold;
    transition: 0.3s;
    margin-right: 20px;

}

.tablinks.selected {
    transition: 0.3s;
    background-color: #FFFB67;
    color: black;
    font-weight: bold;
    /* margin-right: 255px; */
}

/* button.tablinks2:hover {
    transition: 0.3s;
    background-color: #c2c36c;
    color: black;
    font-weight: bold;
} */



.bg {
    width: 100%;
    height: 100%;
    z-index: -200;
    overflow: hidden;

    background-size: cover;
    position: absolute;
}

.bgyellow {
    background-image: url('../assets/big2024/BG.png');
}

.bgred {
    background-image: url('../assets/big2024/BGRed.png');
}

.bgblue {
    background-image: url('../assets/big2024/BGBlue.png');
}




.obg {
    animation: dash 3s;
}


.path {
    stroke-dasharray: 300;
    animation: dash 7s infinite;
    stroke-dashoffset: 1000;
    stroke-width: 3px;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 0;
        stroke: #c2c36c;
    }

    50% {
        stroke-dashoffset: 2000;
        stroke: #284b8c;
    }

    100% {
        stroke-dashoffset: 4000;
        stroke: #c2c36c;
    }
}


.canvas {
    display: block;
    margin: auto;

    right: -20px;
}

#hidden-canvas {
    display: none;
}

.tab {
    overflow: hidden;
    background-color: #f1f1f1;
}

.mainscore {
    font-size: 29px;
    font-weight: 600;
    line-height: 0.9;
    color: #FAF121;
    background-color: #000000;
    padding: 4px 9.5px;
    border-radius: 2.94px;
}
</style>