import { createRouter, createWebHistory } from "vue-router";

import Big2024 from '../views/Big2024.vue'
import Big2024MVP from '../views/Big2024MVP.vue'
import Big2024Cherries from '../views/Big2024Cherries.vue'

const routes = [

    {
      path: '/daily/',
      name: 'daily',
      component: Big2024
    },
    {
      path: '/',
      name: 'week',
      component: Big2024
    },
    {
      path: '/mvp',
      name: 'mvp',
      component: Big2024MVP
    },
    {
      path: '/cherries',
      name: 'cherries',
      component: Big2024Cherries
    },
    {
      path: '/cherries/daily',
      name: 'cherries_daily',
      component: Big2024Cherries
    }
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
