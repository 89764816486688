import { ref, computed } from 'vue'
import { useBattleTools } from './battletools.js'
const battle_data = ref({})
const battle_teams = ref([])
const battle_members = ref([])
const battle_modifiers = ref([])
const battle_sales = ref([])
const battle_compliance = ref([])
const battle_accelerators = ref([])
const battle_additional_points = ref([])
const battle_matches = ref([])
const { isCompliant, isDateApplicable, getFilterDate, getDateRangeAsDayArray } = useBattleTools()

// if filter_date is given, sales will be filtered by date
export function useBattleData(options) {

        // Get the DateRange as an array of objects with a date and an empty teams array.
        const dateWithTeams = computed(() => {
            if (options.daily === true) {
                return getDateRangeAsDayArray(battle_data.value.start_date, battle_data.value.end_date).map((el) =>
                    { return { date: el, teams:[] }}
                )
            } else {
                return [{ date: { index: 0, date: battle_data.value.start_date}, teams:[] }]
            }
        })

        const battle_sales_filtered = computed(() => {
            if (options.cherries === true) {
                return battle_sales.value.filter((el) => el.certified_partner_id === '28835')
            }
            return battle_sales.value.filter((el) => el.certified_partner_id === null)
        })

        /*
            cappedSales is a drop-in replacemnt for battle_sales. It will cap the sales of EESM02 to 3000 per partner and add the difference to the fee_partner.
            To disable it, you can just return battle_sales.value or replace it in the getMemberTotal function for battle_sales.value.
        */
        const cappedSales = computed(() => {

            // We first get the partners that have EESM02 sales and add them to an array with a starting of 3000. From there we will reduce the fee_partner.
            const caps = battle_sales_filtered.value.reduce((acc, cur) => {

                if (cur.campaign_code.startsWith('EESM02')) {
                    const item = acc.find((el) => el.user_id === cur.user_id)
                    if (!item) {
                        acc.push({"fee_eesp02": 3000, "user_id": cur.user_id})
                        }
                    }
                return acc

            },[])

            // We then reduce the sales and update the fee_partner with the difference of the capped fee and the fee_partner.
            return battle_sales_filtered.value.reduce((acc, cur) => {
                if (cur.campaign_code.startsWith('FAKE02')) {
                    const item = caps.find((el) => el.user_id === cur.user_id)
                    if (item) {
                        const calculated_fee = item.fee_eesp02 - cur.sum.fee_partner
                        if (calculated_fee < 0) {
                            item.fee_eesp02 = 0
                            cur.sum.fee_partner = cur.sum.fee_partner + calculated_fee
                        } else {
                            item.fee_eesp02 = calculated_fee
                        }
                    }
                }
                acc.push(cur)
                return acc
            },[])
        })


        const mvps = computed(() => {

            // devide sales into verticals
            const energy = battle_members.value.reduce(getFilterableReducer('E'), []).map(mvpMapper)
            const charity = battle_members.value.reduce(getFilterableReducer('C'), []).map(mvpMapper)
            const media = battle_members.value.reduce(getFilterableReducer('M'), []).map(mvpMapper)
            const lottery = battle_members.value.reduce(getFilterableReducer('L'), []).map(mvpMapper)
            const all = battle_members.value.reduce(getFilterableReducer(), []).map(mvpMapper)

            return {
                "energy": pagedSorter(energy,'energy'),
                "media": pagedSorter(media,'media'),
                "lottery": pagedSorter(lottery,'lottery'),
                "charity": pagedSorter(charity,'charity'),
                "all": pagedSorter(all,'all'),
            }
        })

        const mvpMapper = (el) => {
            return {
                user_id: el.member.battle_user.user_id,
                battle_team: el.member.battle_team,
                first_name: el.member.battle_user.first_name,
                last_name: el.member.battle_user.last_name,
                is_captain: el.member.is_captain,
                compliance_pct: el.compliance.compliance_pct,
                is_compliant: el.compliance.is_compliant,
                count: el.total.total_count,
                total: el.total.total_fee,
                grand_total: el.total.grand_total,
                logo: el.team.logo_filename

            }
        }

        const getFilterableReducer = (filterChar) => (acc, cur) => {
            const team = battle_teams.value.find((el) => el.id === cur.battle_team)
            const compliance = getMemberCompliance(cur.battle_user)

            let sales;
            if (filterChar) {
                sales = cappedSales.value.filter((el) => el.campaign_code.startsWith(filterChar) && el.user_id === cur.battle_user.user_id)
            } else {
                sales = cappedSales.value.filter((el) => el.user_id === cur.battle_user.user_id)
            }

            if (sales.length > 0) {
                const totalized = sales.reduce(scoreReducer(cur, compliance.is_compliant), {
                    total_count: 0,
                    total_fee: 0,
                    accelerated: 0.0,
                    campaign_modifier: 0.0,
                    total_fee_compliant: 0.0,
                    grand_total: 0.0
                })
                acc.push({member: cur, total: totalized, compliance: compliance, team: team})
            }

            return acc
        }

        // sort the results and divide into pages and columns.
        const pagedSorter = (arr, type) => {

            const itemCountPerPage = 36
            const itemCountPerColumn = 12


            const sorted = arr.sort(sorter).map((el, index) =>
                {
                    return {
                        // change
                        "user_id": el.user_id,
                        "battle_team": el.battle_team,
                        "first_name": el.first_name,
                        "last_name": el.last_name,
                        "is_captain": el.is_captain,
                        "compliance_pct": el.compliance_pct,
                        "is_compliant": el.is_compliant,
                        "count": el.count,
                        "total": el.total,
                        "grand_total": el.grand_total,
                        "logo": el.logo,
                        "rank": index
                    }
                }
            )
            const reduced = sorted.reduce((acc, cur, index) => {
                const page = Math.floor(index / itemCountPerPage)

                const item_index_in_page = index - (page * itemCountPerPage)
                const mod = item_index_in_page % itemCountPerColumn

                if (!acc[page]) {
                    const nw = []
                    acc[page] = nw
                }

                if (mod === 0) {
                    const col = []
                    acc[page].push(col)
                    acc.type = type
                }

                acc[page][acc[page].length - 1].push(cur)

                return acc
            },[])

            return reduced
        }


        const sorter = (a, b) =>
            ((b.is_compliant === a.is_compliant) ? 0 : a.is_compliant ? -1 : 1) || (b.grand_total - a.grand_total)


        // Get the teams in total and each member.
        const allTeams = computed(() => {
            let team_filter;

            if (options.cherries === true) {
                team_filter = (el) => el.category === 'cherries'
            } else {
                team_filter = (el) => el.category === null || el.category === 'improvers_community'
            }

            return battle_teams.value.filter(team_filter).map((el) => {
                const members = battle_members.value.filter((mb) => mb.battle_team === el.id).map((el) => { return {
                    user_id: el.battle_user.user_id,
                    first_name: el.battle_user.first_name,
                    last_name: el.battle_user.last_name,
                    is_captain: el.is_captain,
                    battle_team: el.battle_team,
                }})
                return { id: el.id, team_name: el.name, coins: el.coins, members: members, logo: el.logo_filename }
            })
        })

        // For each team in each date, add the team members with their sales data.
        const calculatedTeamsPerDay = computed(() => {
            return dateWithTeams.value.map((dt) => {
                const teams = allTeams.value.map(teamMapper(dt))
                return { date: dt.date, teams: teams }
            })
        })

        // Mapper function to add team members to the team and return the result
        const teamMapper = (dt) => (team) => {


            const team_members = team.members.reduce(teamReducer(dt.date.date),{ "total_count": 0, "total_fee": 0, "total_fee_compliant": 0, "accelerated": 0.0, "campaign_modifier": 0.0, "grand_total": 0.0, "members": [] })

            const sorted_members = team_members.members.sort((a, b) =>
                ((b.is_compliant === a.is_compliant) ? 0 : a.is_compliant ? -1 : 1)
                || (b.total.grand_total - a.total.grand_total)
            )

            /* TEMPORARY FIX FOR LAST ITEM IF 10 MEMBERS IN TEAM */
            let grand_total = team_members.grand_total
            if (sorted_members.length == 10) {
                const last_item = sorted_members.slice(-1)[0]
                grand_total -= last_item.total.grand_total
            }

            // console.log(team_members)
            return {
                id: team.id,
                team_name: team.team_name,
                coins: team.coins,
                members: sorted_members,
                total_count: team_members.total_count,
                total_fee: team_members.total_fee,
                total_fee_compliant: team_members.total_fee_compliant,
                accelerated: team_members.accelerated,
                campaign_modifier: team_members.campaign_modifier,
                grand_total: grand_total,
                logo: team.logo
            }
        }

        // Reducer to update team with totals and member data.
        const teamReducer = (dt) => (acc, cur) => {
            const member = memberCalculator(dt, cur)
            acc.total_count += member.total.total_count
            acc.total_fee += member.total.total_fee
            acc.total_fee_compliant += member.total.total_fee_compliant
            acc.accelerated += member.total.accelerated
            acc.campaign_modifier += member.total.campaign_modifier
            acc.grand_total += member.total.grand_total
            acc.members.push(member)
            return acc
        }

        // The mapper function for each team member.
        const memberCalculator = (dt, battle_member) => {
            const compliance = getMemberCompliance(battle_member)
            const total = getMemberTotal(battle_member, dt, compliance.is_compliant)

            return {
                battle_team: battle_member.battle_team,
                first_name: battle_member.first_name,
                last_name: battle_member.last_name,
                is_captain: battle_member.is_captain,
                compliance_pct: compliance.compliance_pct,
                is_compliant: compliance.is_compliant,
                total: total
            }
        }

        // Add sale data to the members, returned as an object with count, fee, accelerator and campaign modifier.
        const getMemberTotal = (battle_member, dt, is_compliant) => {

            let member_sales;
            if (options.daily === true) {
                // member_sales = battle_sales.value.filter((el) => el.user_id === battle_member.user_id && el.sale_closed_date.slice(0, 10) === dt)
                member_sales = cappedSales.value.filter((el) => el.user_id === battle_member.user_id && el.sale_closed_date.slice(0, 10) === dt)
            } else {
                // member_sales = battle_sales.value.filter((el) => el.user_id === battle_member.user_id)
                member_sales = cappedSales.value.filter((el) => el.user_id === battle_member.user_id)
            }
            return member_sales.reduce(scoreReducer(battle_member, is_compliant), {
                total_count: 0,
                total_fee: 0,
                accelerated: 0.0,
                campaign_modifier: 0.0,
                total_fee_compliant: 0.0,
                grand_total: 0.0 // total_fee + campaign_modifier + accelerated + based on compliance
            })
        }

        // This function is used to reduce a team member's sales to a single object with total fee, count etc.
        const scoreReducer = (battle_member, is_compliant) => (total, current_sale) => {
            total.total_fee += current_sale.sum.fee_partner;
            total.total_fee_compliant = is_compliant ? total.total_fee : 0.0;
            total.total_count += Number(current_sale.count);
            total.accelerated += getAccelerated(battle_member, current_sale)
            total.campaign_modifier += getCampaignModifier(current_sale)
            total.grand_total = is_compliant ? total.total_fee_compliant + total.campaign_modifier + total.accelerated : 0.0;
            return total
        }

        // Fetch a team member's compliance value in percent and whether they are compliant.
        const getMemberCompliance  = (battle_member) => {
            const compliance = battle_compliance.value.find((compliance) => compliance.partner_id == battle_member.user_id)
            const compliance_pct = compliance ? compliance.compliance_pct : undefined
            const is_compliant = isCompliant(compliance_pct)
            return { compliance_pct, is_compliant }
        }

        /*
            Accelerator's can multiply the value of a sale. Different accelerators can be applied to a single sale.
            We get the accelerators of which the date range meets the sale closed date. The team can be empty, which means all teams get
            the accelerator. If the team is not empty, the accelerator is only applied to the team of the sale.
        */
        const getAccelerated = (battle_member, sale) => {
            return battle_accelerators.value.filter((el) =>{
                const applicable = isDateApplicable(sale.sale_closed_date, el.start_date_time, el.end_date_time)
                return (el.battle_team == battle_member.battle_team || el.battle_team == null) && applicable
            }).reduce((acc, cur) => {
                acc += (sale.sum.fee_partner * cur.accelerator) - sale.sum.fee_partner
                return acc
            }, 0.0)
        }

        /*
            Campaign modifiers can add or subtract points from a sale based on the campaign code.
        */
        const getCampaignModifier = (sale) => {
            const campaign_modifier = battle_modifiers.value.find((el1) => el1.campaign_code == sale.campaign_code)
            const campaign_adjustment = campaign_modifier ? campaign_modifier.point_adjustment : 0.0
            return campaign_adjustment * parseInt(sale.count) // since sales could be grouped by campaign.
        }

        /*
            The matches are only used for battles that have matches
        */
        function getMatches() {
            if (options.filter_date == false) {
                return battle_matches.value;
            } else {
                return battle_matches.value.filter((el) => el.match_date == getFilterDate());
            }
        }

        return  { mvps, battle_data, battle_teams,battle_members,battle_modifiers,battle_sales,battle_compliance,battle_accelerators,battle_additional_points, battle_matches, getMatches, calculatedTeamsPerDay, cappedSales}

}