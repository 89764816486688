<template>

    <div class="bg">
    </div>

    <div class="container" v-if="loaded">

        <div class="boardcont">
            <div id="1" class="tabcontent">
                <div class="board">
                    <div class="entrycont" v-for="(team, index) in wrapped.pages[selectedPage].teams" :key="index">


                        <div class="topcont">
                                <div class="team team-rank">{{ getPage(index) }}</div>
                                <div class="team team-group">
                                    <div class="team team-name">{{ team.team_name }}</div>
                                    <div class="team team-score">{{ Math.round(team.grand_total) }}</div>
                                </div>
                                <div class="team team-logo" :class="team.logo"></div>
                        </div>

                        <div class="playercont">
                            <div class="player" v-for="(member, index) in team.members" :key="index" :class="{'notcompliant' : !isCompliant(member.compliance_pct)}">
                                <p class="name">{{ member.first_name }} {{ member.last_name }}</p>
                                <p class="compli">{{ member.compliance_pct ? `${member.compliance_pct}%` : "" }}</p>
                                <p class="score">{{ Math.round(member.total.total_fee + member.total.campaign_modifier + member.total.accelerated) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttoncont">
                <div class="linkwrap">
                    <!-- -->
                    <div class="tablinks dates" :class="[daily ? 'daily': 'week',{selected : index === selectedDateItem && daily}]" @click="selectDate(index)" v-for="(date, index) in filtered" :key="index">
                        {{ getButtonDateText(date.date) }}
                    </div>
                </div>
                <div class="linkwrap">
                    <div  class="tablinks pages" :class="{selected : index === selectedPage}" @click="selectPage(index)" v-for="(page, index) in wrapped.pages" :key="index">
                        {{ getButtonTeamsText(page.index, page.teams.length) }}
                    </div>
                </div>

            </div>
        </div>
        <div class="logocont">
            <div class="logo">

            </div>
        </div>
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');

/*
motivation
*/
.motivation {
    background-image: url("../assets/big2024/TEAMMotivation.png");
}
/*
klikkers
*/
.klikkers {
    background-image: url("../assets/big2024/TEAMDeklikkers.png");
}

/*
ballie
*/
.ballie {
    background-image: url("../assets/big2024/TEAMBallie.png");
}
/*
noname
*/
.noname {
    background-image: url("../assets/big2024/TEAMNoname.png");
}
/*
herres
*/
.herres {
    background-image: url("../assets/big2024/TEAMHerres.png");
}
/*
coldcallers
*/
.coldcallers {
    background-image: url("../assets/big2024/TEAMColdcallers.png");
}
/*
toosie
*/
.toosie {
    background-image: url("../assets/big2024/TEAMToosie.png");
}
/*
dealmakers
*/
.dealmakers {
    background-image: url("../assets/big2024/dealmakers.png");
}
/*
beukers
*/
.beukers {
    background-image: url("../assets/big2024/TEAMBeuker.png");
}
/*
wolves
*/
.wolves {
    background-image: url("../assets/big2024/cherries/Group 198.png");
}
/*
frozen
*/
.frozen{
    background-image: url("../assets/big2024/cherries/Group 201.png");
}
/*
reindeers
*/
.reindeers{
    background-image: url("../assets/big2024/cherries/Group 202.png");
}
/*
angels
*/
.angels{
    background-image: url("../assets/big2024/cherries/Group 200.png");
}
/*
sneeuwvlokjes
*/
.sneeuwvlokjes{
    background-image: url("../assets/big2024/cherries/Group 199.png");
}
/*
pioniers
*/
.pioniers {
    background-image: url("../assets/big2024/TEAMPioniers.png");
}
/*
strijders
*/
.strijders {
    background-image: url("../assets/big2024/Strijders.png");
}
/*
santas
*/
.santas {
    background-image: url("../assets/big2024/cherries/Group 204.png");
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    font-family: "Lexend Deca", sans-serif;
}

.board {
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-around;
}

.boardcont {
    width: 70%;
    height: 100%;
    margin-top: 20px;
    /* display: inline-block; */
    /* align-content: center; */
    padding: 20px;
}

.logocont {
    height: 100%;
    width: 35%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.logo {
    text-align: center;
    background-image: url('../assets/big2024/cherries/group205.png');
    justify-content: center;
    display: flex;
    background-size: 115%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-position-y: -90px;
    background-position-x: -45px;
}

.logo svg {
    width: 300px;
}

.entrycont {
    position: relative;
    width: 340px;
    padding: 10px 20px 20px 20px;
    margin: 10px 0 10px 0;
    background-color: #AA1734;
    border-radius: 20px;
}


.entrycont>svg {
    position: absolute;
    top: 0;
    left: 0;
}

.buttoncont {
    text-align: center;
    display: flex;
}

.topcont {
    display: flex;
}

.teamcont {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.team-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-basis: 100%;
    width: 60%;
    max-width: 60%;
    min-width: 55%;
}

.team-name {
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 19px;
    font-weight: 600;
    color: white;
}

.team-score {
    border-radius: 2.39px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #AA1734;
    margin-top: 5px;
    padding: 2px 8px 2px 8px;
    background-color: white;
    width: fit-content;
}

.team-logo {
    display: flex;
    width: 91px;
    height: 91px;
    margin-left: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.team-logo img{
    width: 91px;
    margin-left: auto;

}

.playercont {
    color: white;
    padding: 14px 0 0 0;
}

.notcompliant {
    opacity: 0.5;
}

.player {
    width: 100%;
    display: flex;
    margin-block-start: 0em;
    margin-block-end: 0em;
    justify-content: space-between;
    /* font-family: "Montserrat", sans-serif; */
    font-optical-sizing: auto;
    /* font-weight: 600; */
    font-style: normal;
    font-size: 19px;
    line-height: 0;
}

p {
    margin-block-start: 0.8em;
    margin-block-end: 0.8em;
}

.name {
    /* margin-block-start: 0px;
    margin-block-end: 0px; */
    width: 70%;

}

.compli {
    text-align: center;
    width: 10%;
}

.score {
    text-align: right;
    width: 20%;
    font-weight: 700;
}

.align-center {
    align-self: center;
}

.just-center {
    justify-self: center;
}

.team-rank {
    font-family: "Bebas Neue";
    font-size: 102px;
    color: white;
    text-align: left;
    height: 91px;
    line-height: 120px;
    margin-right: 15px;
}

.montserrat-semibold {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.montserrat-keivet {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    font-size: 30px;
}

.montserrat-team {
    /* font-family: "Montserrat", sans-serif; */
    font-optical-sizing: auto;
    font-weight: 500;
    /* font-style: normal; */
    font-size: 25px;
    color: white;
}

.rb {
    --bg-size: 400%;
    --color-one: #dbe8c0;
    --color-two: #a0d038;
    background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
    color: transparent;
    background-clip: text;
}

.rbbg {
    --bg-size: 400%;
    --color-one: #dbe8c0;
    --color-two: #a0d038;
    background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
}


.rb {
    animation: move-bg 8s linear infinite;
}

@keyframes move-bg {
    to {
        background-position: var(--bg-size) 0;
    }
}



.rbbg {
    animation: move-bg 8s linear infinite;
}

@keyframes move-bg {
    to {
        background-position: var(--bg-size) 0;
    }
}

.linkwrap {
    display: flex;
    margin-right: 150px;
    margin-left: 18px;
    margin-top: 20px;
}

.tablinks.pages {
    padding: 12px 20px 10px;
    width: unset;
    font-size: 25px;
    background-color: white;
    color: #AA1734;
}

.crowns {
    height: 24px;
    margin-top: 5px;
}

.crown {
    margin-left: 5px;
}

.tablinks.dates.week {
    padding: 10px 20px;
    width: unset;
    font-size: 20px;
    background-color: #AA1734;
    color: white;
}

.tablinks.dates.daily {
    padding: 12px 0px 12px;
    font-size: 20x;
    width: 52px;
    background-color: #AA1734;
    color: white;
}

.tablinks.dates.daily.selected {
    background-color: white;
    color: #AA1734;
}

.tablinks.dates.week::before {
    display: inline-block;
    color: white;
    content:url('../assets/big2024/cherries/eye.png'); /* with class ModalCarrot ??*/
    margin-right: 5px;
    margin-top:5px;
}

.tablinks {
    background-color: black;
    color: #c2c36c;
    margin: 0px 10px 0 10px;
    border-radius: 200px;
    font-size: 20px;
    text-decoration: none;
    outline: none;
    border: none;
    transition: 0.3s;
    margin-left: 35px;

}

.tablinks.selected {
    transition: 0.3s;
    font-weight: bold;
    background-color: white;
    color: #AA1734;
}

/* button.tablinks2:hover {
    transition: 0.3s;
    background-color: #c2c36c;
    color: black;
    font-weight: bold;
} */



.bg {
    width: 100%;
    height: 100%;
    z-index: -200;
    overflow: hidden;
    background-image: url('../assets/big2024/cherries/Dag.png');
    background-size: cover;
    position: absolute;
}


.obg {
    animation: dash 3s;
}


.path {
    stroke-dasharray: 300;
    animation: dash 7s infinite;
    stroke-dashoffset: 1000;
    stroke-width: 3px;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 0;
        stroke: #c2c36c;
    }

    50% {
        stroke-dashoffset: 2000;
        stroke: #284b8c;
    }

    100% {
        stroke-dashoffset: 4000;
        stroke: #c2c36c;
    }
}


.canvas {
    display: block;
    margin: auto;

    right: -20px;
}

#hidden-canvas {
    display: none;
}

.tab {
    overflow: hidden;

    background-color: #f1f1f1;
}

.tabcontent {
    padding: 6px 12px;
}


.mainscore {
    font-family: "Lexend Deca";
    font-size: 37px;
    font-weight: 700;
    line-height: 0.9;
    color: white;
}
</style>

<script setup>

import { onMounted, computed, ref } from 'vue'
import { useBattleData } from '../composables/battledata.js'
import { useBattleLoader } from '../composables/battleloader.js'
import { useBattleTools } from '../composables/battletools.js';
// import { useRouter } from 'vue-router'
// import silkrs from '@/assets/elite2024/silkrs.png';

let reload_timer = undefined
let slide_timer = undefined
// let switch_timer = undefined
let pageSize = 6
// let daily = false
const selectedDateItem = ref(0)
const selectedPage = ref(0)
const daily = ref(false)
daily.value = new URL(window.location.href).pathname.endsWith('/daily')

const { calculatedTeamsPerDay, battle_data } = useBattleData({ daily: daily.value, filter_date: false, sort_excludes_additional_points: true, cherries: true })
const { initBattle, loaded, loadProductionData } = useBattleLoader()
const { getShortDayName, getDateRangeAsDayArray, isCompliant } = useBattleTools()
// const router = useRouter()

const getPage = (index) => {
    return index + 1 + (selectedPage.value * 6)
}

const getButtonTeamsText = (page, teams_length) => {
    const start = 1 + (page * pageSize)
    const end = (start + teams_length) - 1
    return `${start} - ${end}`
}

const setCurrentDate = () => {
    if (daily.value) {
    const date = new Date().toISOString().split('T')[0]
    const current = getDateRangeAsDayArray(battle_data.value.start_date, battle_data.value.end_date).find((item) => item.date === date)
    selectDate(current ? current.index : 0)
    }
}

const dateWithChunkedTeams = computed(() => {
    const items = calculatedTeamsPerDay.value.map((item) => {

        const sorted_teams = item.teams.sort(
            (a, b) => b.grand_total - a.grand_total
        )

        const reduced = sorted_teams.reduce((acc, cur, index) => {
            const page = Math.floor(index / pageSize)
            const found = acc.find((item) => item.index === page)
            if (found) {
                found.teams.push(cur)
            } else {
                const teams = []
                teams.push(cur)
                const new_page = { index: page, teams: teams }
                acc.push(new_page)
            }
            return acc
        }, [])

        return {
            date: item.date.date,
            date_index: item.date.index,
            pages: reduced
        }

    })
    return items
})

const getButtonDateText = (date) => {
    if (daily.value) {
        const shorty = getShortDayName(new Date(date))
        return shorty.charAt(0).toUpperCase() + shorty.slice(1).toLowerCase()
    } else {
        return "Weekoverzicht"
    }
}


const wrapped = computed(() => {
    return dateWithChunkedTeams.value[selectedDateItem.value]
})

const filtered = computed(() => {
    return dateWithChunkedTeams.value.filter((item) => item.date_index < 6)
})

onMounted(async () => {
    await initBattle().then(() => {
        setReloadTimer()
        setSlideTimer()
        // setSwitchTimer()
    })
    setCurrentDate()
    // loadCanvas()
    loaded.value = true
})

function setReloadTimer() {
    if (reload_timer) {
        clearInterval(reload_timer)
        reload_timer = undefined
    }
    reload_timer = setInterval(() => loadProductionData(), 120000)
}

function setSlideTimer() {
    if (slide_timer) {
        clearInterval(slide_timer)
        slide_timer = undefined
    }
    slide_timer = setInterval(() => slidePage(), 50000)
}

// function setSwitchTimer() {
//     if (switch_timer) {
//         clearInterval(switch_timer)
//         switch_timer = undefined
//     }
//     switch_timer = setInterval(() => switchRoute(), 5000)
// }

// function switchRoute() {
//     if (daily.value) {
//         router.push('/')
//     } else {
//         router.push('/daily')
//     }
// }


function slidePage() {
    const currentPageCount = dateWithChunkedTeams.value[selectedDateItem.value].pages.length
    if (selectedPage.value < currentPageCount - 1) {
        selectedPage.value += 1
    } else {
        selectedPage.value = 0
        // slideDate()

    }
}

// function slideDate() {
//     const dateCount = dateWithChunkedTeams.value.length
//     if (selectedDateItem.value < dateCount - 1) {
//         selectedDateItem.value += 1
//     } else {
//         selectedDateItem.value = 0
//     }
// }

function selectDate(dateIndex) {
    selectedDateItem.value = dateIndex
    selectedPage.value = 0
    setSlideTimer()
}

function selectPage(pageIndex) {
    selectedPage.value = pageIndex
    setSlideTimer()
}


// function loadCanvas() {
//     const canvas = document.getElementById("canvas");
//     const context = canvas.getContext("2d");

//     const image = new Image();
//     image.src = '/silkrs.png';
//     // console.log(image.src);
//     image.onload = function () {
//         canvas.width = image.width;
//         canvas.height = image.height;
//         animate();
//     };

//     let offset = 0;

//     function animate() {
//         context.clearRect(0, 0, canvas.width, canvas.height);

//         for (let y = 0; y < canvas.height; y++) {
//             const sinOffset = 80 * Math.sin(y / 80 + offset); // Wave scale / detail level

//             context.drawImage(image, 0, y, canvas.width, 1, sinOffset, y, canvas.width, 1);
//         }

//         offset += 0.002; // Distortion speed / animatiesnelheid
//         requestAnimationFrame(animate);
//     }
// }


</script>