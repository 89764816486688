<template>

    <div class="bg">
    </div>

    <div class="container" v-if="loaded">
        <div class="logocont">
            <div class="logo">
            </div>
        </div>
        <div class="boardcont">
            <div id="1" class="tabcontent" >
                <div class="board" v-if="wrapped.pages[selectedPage]">
                    <div class="entrycont" v-for="(team, index) in wrapped.pages[selectedPage].teams" :key="index">
                        <div class="topcont">
                            <div class="team team-rank">{{ getPage(index) }}</div>
                            <div class="team team-group">
                                <div class="team team-name">{{ team.team_name }}</div>
                                <div class="team team-score">{{ Math.round(team.grand_total) }}</div>
                            </div>
                            <div class="team team-logo" :class="team.logo"></div>
                        </div>
                        <div class="playercont">
                            <div class="player" v-for="(member, index) in team.members" :key="index" :class="{'notcompliant' : !isCompliant(member.compliance_pct)}">
                                <p class="name">{{ member.first_name }} {{ member.last_name }}</p>
                                <!-- <p class="compli">{{ member.compliance_pct ? `${member.compliance_pct}%` : "" }}</p> -->
                                <p class="score">{{ Math.round(member.total.total_fee + member.total.campaign_modifier + member.total.accelerated) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttoncont">
                <div class="linkwrap">
                    <!-- -->
                    <div class="tablinks dates" :class="[daily ? 'daily': 'week',{selected : index === selectedDateItem && daily}]" @click="selectDate(index)" v-for="(date, index) in filtered" :key="index">
                        {{ getButtonDateText(date.date) }}
                    </div>
                </div>
                <div class="linkwrap">
                    <div  class="tablinks pages" :class="{selected : index === selectedPage}" @click="selectPage(index)" v-for="(page, index) in wrapped.pages" :key="index">
                        {{ getButtonTeamsText(page.index, page.teams.length) }}
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');


.container {
    /* height: 100%; */
    width: 100%;
    display: flex;
    font-family: "Teko", sans-serif;
    box-sizing: border-box;
    margin-top: 60px;
}

.board {
    display: flex;
    flex-wrap: wrap;
}

.boardcont {
    min-height: 900px;
    width:75%;
    margin-left: -85px;
    transform: skew(-16.3deg);
}

.logocont {
    width:22%;
    justify-content: flex-end;
    display: flex;
}

.logo {
    text-align: center;
    width: 370px;
    height: 160px;

    background-image: url("../assets/big2024/LOGOsm.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 110% 0%;
    background-size: contain;
    justify-content: center;
    display: flex;
}

/*
motivation
*/
.motivation {
    background-image: url("../assets/big2024/TEAMMotivation.png");
}
/*
klikkers
*/
.klikkers {
    background-image: url("../assets/big2024/TEAMDeklikkers.png");
}

/*
ballie
*/
.ballie {
    background-image: url("../assets/big2024/TEAMBallie.png");
}
/*
noname
*/
.noname {
    background-image: url("../assets/big2024/TEAMNoname.png");
}
/*
herres
*/
.herres {
    background-image: url("../assets/big2024/TEAMHerres.png");
}
/*
coldcallers
*/
.coldcallers {
    background-image: url("../assets/big2024/TEAMColdcallers.png");
}
/*
toosie
*/
.toosie {
    background-image: url("../assets/big2024/TEAMToosie.png");
}
/*
dealmakers
*/
.dealmakers {
    background-image: url("../assets/big2024/dealmakers.png");
}
/*
beukers
*/
.beukers {
    background-image: url("../assets/big2024/TEAMBeuker.png");
}
/*
wolves
*/
.wolves {
    background-image: url("../assets/big2024/cherries/Group 198.png");
}
/*
frozen
*/
.frozen{
    background-image: url("../assets/big2024/cherries/Group 201.png");
}
/*
reindeers
*/
.reindeers{
    background-image: url("../assets/big2024/cherries/Group 202.png");
}
/*
angels
*/
.angels{
    background-image: url("../assets/big2024/cherries/Group 200.png");
}
/*
sneeuwvlokjes
*/
.sneeuwvlokjes{
    background-image: url("../assets/big2024/cherries/Group 199.png");
}
/*
pioniers
*/
.pioniers {
    background-image: url("../assets/big2024/TEAMPioniers.png");
}
/*
strijders
*/
.strijders {
    background-image: url("../assets/big2024/Strijders.png");
}
/*
santas
*/
.santas {
    background-image: url("../assets/big2024/cherries/Group 204.png");
}


.entrycont {
    width: 380px;
    padding: 20px 30px 30px 30px;
    background-color: #ffffff;
    border-radius: 9px;
    margin-bottom: 30px;
    margin-right: 30px;

}

.buttoncont {
    display: flex;
    height: 52px;
    width: 96.5%;
    justify-content: space-between;
}

.notcompliant {
    opacity: 0.5;
}

.player {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
    line-height: 0px;
    height: 25px;
}

.name {
    width: 70%;
}

.compli {
    text-align: center;
    width: 10%;
}

.topcont {
    display: flex;
    flex-direction: column;
    height: 91px;
    width: 100%;
    flex-wrap: wrap;
    /* justify-content: flex-start; */
}

.team-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-basis: 100%;
    width: 55%;
    max-width: 55%;
    min-width: 45%;
}

.team-rank {
    font-size: 140px;
    font-weight: 600;
    line-height: 110px;
    height: 91px;
    flex-basis: 50px;
}

.team-name {
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    font-size: 32px;
    line-height: 27px;
}

.team-score {
    border-radius: 2.39px;
    font-weight: 700;
    font-size: 29px;
    line-height: 24px;
    color: #FAF121;
    padding: 6px 10px 2px 10px;
    background-color: #000000;
    width: fit-content;
}

.team-logo {
    display: flex;
    width: 91px;
    height: 91px;
    margin-left: auto;
    background-size: contain;

}

.team-logo img{
    width: 91px;
    margin-left: auto;

}

.align-center {
    align-self: center;
}

.just-center {
    justify-self: center;
}

.rb {
    --bg-size: 400%;
    --color-one: #dbe8c0;
    --color-two: #a0d038;
    background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
    color: transparent;
    background-clip: text;
}

.rbbg {
    --bg-size: 400%;
    --color-one: #dbe8c0;
    --color-two: #a0d038;
    background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
}


.rb {
    animation: move-bg 8s linear infinite;
}

@keyframes move-bg {
    to {
        background-position: var(--bg-size) 0;
    }
}



.rbbg {
    animation: move-bg 8s linear infinite;
}

@keyframes move-bg {
    to {
        background-position: var(--bg-size) 0;
    }
}

.linkwrap {
    display: flex;
    justify-content: center;
    align-items: center;
}



.crowns {
    height: 24px;
    margin-top: 5px;
}

.crown {
    margin-left: 5px;
}

.tablinks.dates.week {
    background-color: #ffffff;
    color: #091938;
}

/* .tablinks.dates.daily {
    padding: 10px 0px;
    width: 52px;
} */

/* .tablinks.dates.week::before {
    display: inline-block;
    content:url('../assets/elite2024/eye.png');
    margin-right: 5px;
} */

.tablinks {
    background-color: #F6B400;
    color: black;
    margin: 0px 10px 0px 0px;
    border-radius: 9px;
    font-size: 25px;
    text-decoration: none;
    outline: none;
    border: none;
    font-weight: bold;
    transition: 0.3s;
    padding: 10px 20px;


}

.tablinks.selected {
    transition: 0.3s;
    background-color: #FFFB67;
    color: black;
    font-weight: bold;
    /* margin-left: 255px; */
}


.bg {
    width: 100%;
    height: 100%;
    z-index: -200;
    overflow: hidden;
    background-image: url('../assets/big2024/BG.png');
    background-size: cover;
    position: absolute;
}


.obg {
    animation: dash 3s;
}


.path {
    stroke-dasharray: 300;
    animation: dash 7s infinite;
    stroke-dashoffset: 1000;
    stroke-width: 3px;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 0;
        stroke: #c2c36c;
    }

    50% {
        stroke-dashoffset: 2000;
        stroke: #284b8c;
    }

    100% {
        stroke-dashoffset: 4000;
        stroke: #c2c36c;
    }
}


.canvas {
    display: block;
    margin: auto;

    right: -20px;
}

#hidden-canvas {
    display: none;
}

.tab {
    overflow: hidden;
    background-color: #f1f1f1;
}

.mainscore {
    font-size: 29px;
    font-weight: 600;
    line-height: 0.9;
    color: #FAF121;
    background-color: #000000;
    padding: 4px 9.5px;
    border-radius: 2.94px;
}
</style>

<script setup>

import { onMounted, computed, ref } from 'vue'
import { useBattleData } from '../composables/battledata.js'
import { useBattleLoader } from '../composables/battleloader.js'
import { useBattleTools } from '../composables/battletools.js';
// import { useRouter } from 'vue-router'
// import silkrs from '@/assets/elite2024/silkrs.png';

let reload_timer = undefined
let slide_timer = undefined
// let switch_timer = undefined
let pageSize = 6
// let daily = false
const selectedDateItem = ref(0)
const selectedPage = ref(0)
const daily = ref(false)
daily.value = new URL(window.location.href).pathname.startsWith('/daily')

const { calculatedTeamsPerDay, battle_data } = useBattleData({ daily: daily.value, filter_date: false, sort_excludes_additional_points: true, cherries: false })
const { initBattle, loaded, loadProductionData } = useBattleLoader()
const { getShortDayName, getDateRangeAsDayArray, isCompliant } = useBattleTools()
// const router = useRouter()

const getPage = (index) => {
    return index + 1 + (selectedPage.value * 6)
}

const getButtonTeamsText = (page, teams_length) => {
    const start = 1 + (page * pageSize)
    const end = (start + teams_length) - 1
    return `${start} - ${end}`
}

const setCurrentDate = () => {
    if (daily.value) {
    const date = new Date().toISOString().split('T')[0]
    const current = getDateRangeAsDayArray(battle_data.value.start_date, battle_data.value.end_date).find((item) => item.date === date)
    selectDate(current ? current.index : 0)
    }
}

const dateWithChunkedTeams = computed(() => {

    const items = calculatedTeamsPerDay.value.map((item) => {

        const sorted_teams = item.teams.sort(
            (a, b) => b.grand_total - a.grand_total
        )

        const reduced = sorted_teams.reduce((acc, cur, index) => {
            const page = Math.floor(index / pageSize)
            const found = acc.find((item) => item.index === page)
            if (found) {
                found.teams.push(cur)
            } else {
                const teams = []
                teams.push(cur)
                const new_page = { index: page, teams: teams }
                acc.push(new_page)
            }
            return acc
        }, [])

        return {
            date: item.date.date,
            date_index: item.date.index,
            pages: reduced
        }

    })
    return items
})

const getButtonDateText = (date) => {
    if (daily.value) {
        const shorty = getShortDayName(new Date(date))
        return shorty.charAt(0).toUpperCase() + shorty.slice(1).toLowerCase()
    } else {
        return "Weekoverzicht"
    }
}


const wrapped = computed(() => {
    return dateWithChunkedTeams.value[selectedDateItem.value]
})

const filtered = computed(() => {
    return dateWithChunkedTeams.value.filter((item) => item.date_index < 6)
})

onMounted(async () => {
    await initBattle().then(() => {
        setReloadTimer()
        setSlideTimer()
    })
    setCurrentDate()
    loaded.value = true
})

function setReloadTimer() {
    if (reload_timer) {
        clearInterval(reload_timer)
        reload_timer = undefined
    }
    reload_timer = setInterval(() => loadProductionData(), 120000)
}

function setSlideTimer() {
    if (slide_timer) {
        clearInterval(slide_timer)
        slide_timer = undefined
    }
    slide_timer = setInterval(() => slidePage(), 50000)
}

function slidePage() {
    const currentPageCount = dateWithChunkedTeams.value[selectedDateItem.value].pages.length
    if (selectedPage.value < currentPageCount - 1) {
        selectedPage.value += 1
    } else {
        selectedPage.value = 0
        // slideDate()

    }
}

function selectDate(dateIndex) {
    selectedDateItem.value = dateIndex
    selectedPage.value = 0
    setSlideTimer()
}

function selectPage(pageIndex) {
    selectedPage.value = pageIndex
    setSlideTimer()
}


</script>